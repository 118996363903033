import BaseApi from "./BaseApi";

export default class Languages extends BaseApi{
    construct() {}

    static async list(params, tenant) {
        let url = Languages.getFullApiUrl(tenant) + "list";

        return Languages.getAxiosInstance().get(url,{params: params});
    }

    static getFullApiUrl(tenant = null){
        let domain = location.pathname.split('/')[1]
        if(tenant != null)
            domain = tenant
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/client/languages/";
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/client/languages/";
    }
}