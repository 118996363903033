<template>
    <validation-observer ref="form" v-slot="{ invalid }">
        <v-form v-if="!validateEmail" :readonly="isRegistered" @submit.prevent="submit" class="ma-0 pa-0">
            <ErrorSnackbar v-model="error.show" :message="error.message" />
            <v-container fluid>
                <v-card style="margin: auto; display: block;"
                    class="py-3 my-3 rounded-xl elevation-0 fill-height w-70-md" v-if="emailSent == false">
                    <v-card-text>
                        <v-row class="mt-n10">
                            <v-col cols="12" class="text-h4 font-weight-bold">
                                {{ $t('subscriptions.register_view.client_registry') }}
                            </v-col>
                            <v-col cols="12" md="6" class="mt-n4">
                                <v-row dense>
                                    <v-col cols="12" class="font-weight-bold">
                                        {{ $t('subscriptions.register_view.company_data') }}
                                    </v-col>
                                    <v-col cols="12">
                                        <validation-provider v-slot="{ errors }"
                                            :vid="$t('subscriptions.register_view.company_name')"
                                            :name="$t('subscriptions.register_view.company_name')" rules="required">
                                            <v-text-field dense v-model="fields.company.name"
                                                :label="$t('subscriptions.register_view.company_name')"
                                                :error-messages="errors" />
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12">
                                        <validation-provider v-slot="{ errors }"
                                            :vid="$t('subscriptions.register_view.company_address')"
                                            :name="$t('subscriptions.register_view.company_address')" rules="required">
                                            <v-text-field dense v-model="fields.company.address" :error-messages="errors"
                                                :label="$t('subscriptions.register_view.company_address')" />
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <validation-provider v-slot="{ errors }"
                                            :vid="$t('subscriptions.register_view.postal_code')"
                                            :name="$t('subscriptions.register_view.postal_code')" rules="required">
                                            <v-text-field dense v-model="fields.company.postal_code"
                                                :error-messages="errors"
                                                :label="$t('subscriptions.register_view.postal_code')" />
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <validation-provider v-slot="{ errors }"
                                            :vid="$t('subscriptions.register_view.local')"
                                            :name="$t('subscriptions.register_view.local')" rules="required">
                                            <v-text-field dense v-model="fields.company.local" :error-messages="errors"
                                                :label="$t('subscriptions.register_view.local')" />
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12">
                                        <validation-provider v-slot="{ errors }"
                                            :vid="$t('subscriptions.register_view.country')"
                                            :name="$t('subscriptions.register_view.country')" rules="required">
                                            <v-autocomplete dense outlined v-model="fields.company.country"
                                                :error-messages="errors" :label="$t('subscriptions.register_view.country')"
                                                :items="['Portugal', 'Espanha']" />
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12">
                                        <validation-provider v-slot="{ errors }"
                                            :vid="$t('subscriptions.register_view.nif')"
                                            :name="$t('subscriptions.register_view.nif')" rules="required|pt_nif">
                                            <v-text-field dense v-model="fields.company.nif" :error-messages="errors"
                                                :label="$t('subscriptions.register_view.nif')" @blur="checkData" />
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12">
                                        <validation-provider v-slot="{ errors }"
                                            :vid="$t('subscriptions.register_view.email')"
                                            :name="$t('subscriptions.register_view.email')" rules="required|email">
                                            <v-text-field dense v-model="fields.company.email" :error-messages="errors"
                                                :label="$t('subscriptions.register_view.email')" />
                                        </validation-provider>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="6" class="mt-n4">
                                <v-row dense>
                                    <v-col cols="12" class="font-weight-bold">
                                        {{ $t('subscriptions.register_view.account_manager') }}
                                    </v-col>
                                    <v-col cols="12">
                                        <validation-provider v-slot="{ errors }"
                                            :vid="$t('subscriptions.register_view.name')"
                                            :name="$t('subscriptions.register_view.name')" rules="required">
                                            <v-text-field dense v-model="fields.manager.name" :error-messages="errors"
                                                :label="$t('subscriptions.register_view.name')" />
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12">
                                        <validation-provider v-slot="{ errors }"
                                            :vid="$t('subscriptions.register_view.name')"
                                            :name="$t('subscriptions.register_view.name')" rules="required">
                                            <v-text-field dense v-model="fields.manager.function" :error-messages="errors"
                                                :label="$t('subscriptions.register_view.position')" />
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12">
                                        <validation-provider v-slot="{ errors }"
                                            :vid="$t('subscriptions.register_view.manager_email')"
                                            :name="$t('subscriptions.register_view.manager_email')" rules="required|email">
                                            <v-text-field dense v-model="fields.manager.email" :error-messages="errors"
                                                :label="$t('subscriptions.register_view.manager_email')"
                                                @blur="checkData" />
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" v-if="!isRegistered">
                                        <validation-provider v-slot="{ errors }" vid="password"
                                            :name="$t('subscriptions.register_view.password')" rules="required|password_size|special_char_1|capital_letter|small_letter|one_number">
                                            <v-text-field dense v-model="fields.manager.password" :error-messages="errors"
                                                type="password" :label="$t('subscriptions.register_view.password')" />
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" class="mt-4" v-if="!isRegistered">
                                        <validation-provider :name="$t('subscriptions.register_view.repete')"
                                            :vid="$t('subscriptions.register_view.repete')"
                                            rules="required|password:@password" v-slot="{ errors }">
                                            <v-text-field dense v-model="password_confirmation"
                                                :label="$t('subscriptions.register_view.repete')" type="password"
                                                :error-messages="errors"></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12">
                                        <validation-provider v-slot="{ errors }"
                                            :vid="$t('subscriptions.register_view.mobile')"
                                            :name="$t('subscriptions.register_view.mobile')" rules="required">
                                            <v-text-field dense v-model="fields.manager.telephone" :error-messages="errors"
                                                :label="$t('subscriptions.register_view.mobile')" />
                                        </validation-provider>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="mt-n4" justify="center" align="center" v-if="!isRegistered" no-gutters>
                            <v-col cols="2" sm="auto" justify="center" align="center">
                                <v-checkbox class="mt-4" v-model="fields.terms_conditions">
                                </v-checkbox>
                            </v-col>
                            <v-col cols="10" sm="auto" justify="center" align="start">
                                <span v-html="$t('subscriptions.register_view.terms')"></span>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" class="mt-n6">
                            <v-col cols="12" md="auto">
                                <v-btn icon fab outlined x-small @click="generateCaptch">
                                    <v-icon color="black">mdi-refresh</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="12" md="auto">
                                <div style="width:100%;" id="image" class="inline"></div>
                            </v-col>
                            <v-col cols="12" md="auto">
                                <v-text-field v-model="captcha" label="Captcha *" />
                            </v-col>
                        </v-row>
                        <v-row class="mt-n6">
                            <v-col cols="12" align="center">
                                <v-btn :disabled="!proceedButton(invalid)" rounded depressed color="primary" type="submit"
                                    class="btn-bottom">
                                    {{ $t('subscriptions.register_view.next') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <div v-else>
                    <!-- DESKTOP VERSION -->
                    <v-card style="margin: auto;"
                        class="py-16 my-16 rounded-xl elevation-0 fill-height w-50-md d-none d-sm-block">
                        <v-row>
                            <v-col cols="12" class="text-md-center text-h4 font-weight-bold text-uppercase">
                                {{ $t('subscriptions.register_view.verify_email') }}
                            </v-col>
                            <v-col cols="12" class="mt-6 text-body-1">
                                {{ $t('subscriptions.register_view.before_you_go') }}
                            </v-col>
                            <v-col cols="12" class="mt-6 text-body-1">
                                {{ $t('subscriptions.register_view.no_received') }}
                            </v-col>
                            <v-col cols="12" align="center" class="mt-8">
                                <v-btn @click="submit" rounded class="primary">{{
        $t('subscriptions.register_view.again')
    }}</v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                    <!-- DESKTOP VERSION END -->
                    <!-- MOBILE VERSION -->
                    <v-card style="margin: auto;" class="py-4 my-4 rounded-xl elevation-0 fill-height d-sm-none">
                        <v-row>
                            <v-col cols="12">
                                <span class="text-h4 font-weight-bold text-uppercase black--text">
                                    {{ $t('subscriptions.register_view.verify_email1') }}
                                    <br />
                                    {{ $t('subscriptions.register_view.verify_email2') }}
                                </span>
                            </v-col>
                            <v-col cols="12" class="mt-6 text-body-1">
                                {{ $t('subscriptions.register_view.before_you_go') }}
                            </v-col>
                            <v-col cols="12" class="mt-6 text-body-1">
                                {{ $t('subscriptions.register_view.no_received') }}
                            </v-col>
                            <v-col cols="12" align="center" class="mt-8">
                                <v-btn @click="submit" rounded class="primary">{{
        $t('subscriptions.register_view.again') }}</v-btn>
                            </v-col>
                            <v-btn class="justify-center w-100 d-none d-flex btn-no-style" style="position: fixed;
                    bottom: 40px; left: 0;" onClick="window.location.href='https://ignoto.eu'">
                                <v-img src="@/assets/icons/logo_preto.jpg" max-width="175" />
                            </v-btn>
                        </v-row>
                    </v-card>
                    <!-- MOBILE VERSION END -->
                </div>
            </v-container>
        </v-form>
        <v-container fluid v-else>
            <!-- DESKTOP VERSION -->
            <v-card style="margin: auto;"
                class="py-16 my-16 rounded-xl elevation-0 fill-height w-50-md d-none d-sm-block">
                <v-row align="center" justify="center">
                    <v-col cols="12" class="text-md-center text-h4 font-weight-bold text-uppercase">
                        {{ $t('subscriptions.register_view.verify_email') }}
                    </v-col>
                    <v-col cols="12" class="mt-6 text-md-center text-body-1">
                        {{ $t('subscriptions.register_view.before_you_go') }}
                    </v-col>
                    <v-col cols="12" class="mt-6 text-md-center text-body-1">
                        {{ $t('subscriptions.register_view.no_received') }}
                    </v-col>
                    <v-col cols="12" align="center" class="mt-8">
                        <v-btn class="primary" rounded @click="$emit('goToLogin')">Login</v-btn>
                    </v-col>
                </v-row>
            </v-card>
            <!-- DESKTOP VERSION END -->
            <!-- MOBILE VERSION -->
            <v-card style="margin: auto;" class="py-4 my-4 rounded-xl elevation-0 fill-height d-sm-none">
                <v-row>
                    <v-col cols="12">
                        <span class="text-h4 font-weight-bold text-uppercase black--text">
                            {{ $t('subscriptions.register_view.verify_email1') }}
                            <br />
                            {{ $t('subscriptions.register_view.verify_email2') }}
                        </span>
                    </v-col>
                    <v-col cols="12" class="mt-6 text-body-1">
                        {{ $t('subscriptions.register_view.before_you_go') }}
                    </v-col>
                    <v-col cols="12" class="mt-6 text-body-1">
                        {{ $t('subscriptions.register_view.no_received') }}
                    </v-col>
                    <v-btn class="justify-center w-100 d-none d-flex btn-no-style" style="position: fixed;
                    bottom: 40px; left: 0;" onClick="window.location.href='https://ignoto.eu'">
                        <v-img src="@/assets/icons/logo_preto.jpg" max-width="175" />
                    </v-btn>
                </v-row>
            </v-card>
            <!-- MOBILE VERSION END -->
        </v-container>
        <v-dialog v-model="loader" hide-overlay persistent width="500">
            <v-card color="primary" dark>
                <v-card-text class="text-center pt-5">
                    {{ $t('globals.please_wait') }}
                    <v-progress-linear indeterminate color="white" class="mb-0 mt-8 mb-8"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </validation-observer>
</template>
<script>
import Tenant from "@/api/Tenant.js"
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';

export default {
    name: "RegisterAccount",
    components: {
        ErrorSnackbar,
    },
    props: {
        fields: Object,
        isRegistered: {
            typeof: Boolean,
            default: false
        },
        showEmailConfirm:{
            typeof: Boolean,
            default: false
        }
    },
    watch:{
        showEmailConfirm:{
            immediate: true,
            handler(val){
                this.validateEmail = val
            }
        }
    },
    data() {
        return {
            loading: true,
            validateEmail: false,
            loader: false,
            emailSent: false,
            password_confirmation: null,
            readOnly: false,
            captcha: null,
            error: {
                show: false,
                message: ''
            },
            uniquechar: ''
        }
    },
    mounted() {
        if(this.showEmailConfirm)
            this.validateEmail = true

        this.generateCaptch()
        this.loading = false
    },
    methods: {
        generateCaptch() {
            const randomchar = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            // Generate captcha for length of
            // 5 with random character
            this.uniquechar = ''
            for (let i = 1; i < 6; i++) {
                this.uniquechar += randomchar.charAt(
                    Math.random() * randomchar.length)
            }

            document.getElementById("image").innerHTML = this.uniquechar
        },
        checkData() {
            let data = {
                nif: this.fields.company.nif,
                email: this.fields.manager.email
            }

            Tenant.checkData(data).then((resp) => {
                let info = resp.data

                if (info.nif != null) {
                    this.error.message = 'Esse NIF já está registado com outra conta'
                    this.error.show = true
                    this.fields.company.nif = null
                }
                if (info.email != null) {
                    this.error.message = 'Esse email já está registado com outra conta'
                    this.error.show = true
                    this.fields.manager.email = null
                }
            })
        },
        proceedButton(invalid) {
            if (invalid == false)
                if (this.fields.terms_conditions == true)
                    if (this.captcha == this.uniquechar)
                        return true

            return false
        },
        submit() {
            if (this.$store.tenant_company != null) {
                this.$emit('next')
                return
            }
            this.loader = true
            Tenant.create(this.fields).then(() => {
                this.$router.push('/confirmemail')
                window.location.reload()
            })
        }
    }
}
</script>
<style>
#image {
    font-weight: 400;
    user-select: none;
    text-decoration: line-through;
    font-style: italic;
    padding: 5px;
    font-size: x-large;
    border: black 1px solid;

}

.inline {
    display: inline-block;
}

.center-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}
</style>