<template>
    <section class="mt-16">
        <v-breadcrumbs
            large
            :items="crumbs"
            divider="/"
            class="pl-0"
        ></v-breadcrumbs>

        <h1 class="primary--text">
            <v-icon color="primary">mdi-pencil-ruler</v-icon> <span>{{$t('menu.white_labeling')}}</span>
        </h1>


        <div class="mt-4" style="position:relative" v-if="!loading">
            <v-tabs
                v-model="tab"
                background-color="transparent"
                color="basil"
                grow
            >
                <v-tab :key="0">
                    Introdução à denúncia
                </v-tab>
                <v-tab :key="1">
                    Mensagem Agradecimento de denúncia
                </v-tab>
                <v-tab :key="2">
                    Design
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item
                    :key="0"
                >
                    <v-container>
                        <Introduction v-if="!loading" :fields="introduction" @reload="fillBaseData"/>
                    </v-container>
                    
                </v-tab-item>
                <v-tab-item
                    :key="1"
                >
                    <v-container>
                        <ThankYou v-if="!loading" :fields="thanks" @reload="fillBaseData"/>
                    </v-container>
                    
                </v-tab-item>
                <v-tab-item :key="2">
                    <v-container>
                        <v-form @submit.prevent="submit">
                            <v-card elevation="10" :style="this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75) !important;'" :class="$root.$vuetify.theme.isDark ? 'secondary elevation-0' : ''">
                                <v-card-title class="primary white--text">{{$t('white_labeling.logo')}}</v-card-title>
                                <v-card-text>
                                    <v-row class="mt-4">
                                        <v-col cols="12" md="6">
                                            <v-card-text class="mt-n6">
                                                <v-file-input
                                                    v-model="fields.logo"
                                                    :rules="rules"
                                                    color="primary"
                                                    accept="image/png, image/jpeg, image/bmp"
                                                    :placeholder="$t('white_labeling.logo')"
                                                    prepend-icon="mdi-camera"
                                                    :label="$t('white_labeling.logo')"
                                                    @change="onFileChange"
                                                ></v-file-input>
                                            </v-card-text>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-card-title class="mt-n10 text-body-1 font-weight-bold">{{$t('white_labeling.preview')}}:</v-card-title>
                                            <v-divider></v-divider>
                                            <v-container class="fill-height" fluid>
                                                <v-img v-if="img_url" 
                                                    style="display: block; margin:auto;"
                                                    :src="img_url" 
                                                    ref="logoImage"
                                                    @load="loadedImage"
                                                    contain
                                                    :aspect-ratio="16/9"
                                                    class="center mt-2"         
                                                    max-width="350px"
                                                    max-height="350px"/>
                                            </v-container>
                                            <v-divider></v-divider>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" class="text-body-1 font-weight-bold">
                                            {{$t('white_labeling.color')}}
                                        </v-col>
                                        <v-col cols="12">
                                            <v-color-picker
                                                hide-mode-switch
                                                mode="hexa"
                                                v-model="fields.primary"
                                            ></v-color-picker>
                                        </v-col>
                                        <v-col cols="12" v-if="false">
                                            <v-color-picker
                                                v-model="fields.secondary"
                                            ></v-color-picker>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <v-card-actions class="text-center justify-center">
                                <v-btn elevation="10" class="mt-4" depressed color="warning" type="submit">
                                    {{$t('globals.save')}}
                                </v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-container>                    
                </v-tab-item>
            </v-tabs-items>
            
            
        </div>
        <ErrorSnackbar v-model="error.show" :message="error.message"/>
        <DialogSuccess :opened="success" @on-ok="onSuccess" >
            {{$t('white_labeling.success')}}
        </DialogSuccess>


        
    </section>
</template>
<script>
//import Configurations from "@/api/Configurations.js";
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';
import Branding from "@/api/Branding.js";
import Introduction from '@/components/complaints/introduction/Introduction.vue'
import ThankYou from '@/components/complaints/thanks/ThankYou.vue'
import Complaints from '@/api/Complaints.js'

export default{
    components:{
        DialogSuccess,
        ErrorSnackbar,
        Introduction,
        ThankYou
    },
    watch:{
        imageObject:{
            immediate: false,
            handler(){
                /*if(i.width > 200 || i.height > 200){
                    this.error.message = "Imagem muito grande. Tamanho máximo: 200x200"
                    this.error.show = true
                }*/
            }
        }
    },
    beforeCreate(){
      document.title = this.$t('app_name') + ' - ' + this.$t('menu.white_labeling');
    },
    data(){
        return{
            loading: false,
            tab: 0,
            img_url: null,
            thanks:[],
            success: false,
            introduction: null,
            imageObject: null,
            fields:{
                primary:'#FFF',
                secondary:null
            },    
            error: {
                title: '',
                message: '',
                show:false,
                showExtensive: false
            },            
            rules: [
                value => !value || value.size < 2000000 || 'Image to big',
            ],   
        }
    },
    async mounted(){
        this.loading = true
        await Branding.get().then(({data}) => {

            
            
            if(data.logo != null)
                this.img_url = data.logo.replace(/([^:])(\/\/+)/g, '$1/'); //process.env.VUE_APP_API_URL + data.logo.replace(/([^:])(\/\/+)/g, '$1/');
            
            if(data.theme != null){
                let theme = JSON.parse(data.theme.value)
                this.fields.primary = theme.primary
                this.fields.secondary = theme.secondary
            }

            this.fillBaseData()
            
            this.loading = false
        });
    },
    methods:{
        fillBaseData(){
            Complaints.getIntroduction().then(({data}) => {
                this.introduction = data
            })      
            Complaints.getThankYou().then(({data}) => {
                this.thanks = data
            }) 
        },
        loadedImage(){
            const img = new Image();
            img.src = this.$refs.logoImage.src;
            img.onload = () => {
                this.imageObject = img
                this.imageObject.width = img.width
                this.imageObject.height = img.height
            };
        },
        onFileChange(file){
            if(file == null)
                return

            let tamanho = 2000000; // 2 MB

            if(file.size >= tamanho){
                this.fields.logo = null
                this.error.message = 'Image bigger than 2MB'
                this.error.show = true
                return
            }

            this.logo_changed = true;

            const reader = new FileReader();

            reader.onload = e => {
                this.img_url = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        onSuccess(){
            this.success = false
        },
        async submit(){
            const formData = new FormData();
            if(this.fields.logo != null)
                formData.append('logo', this.fields.logo)
                
                console.log(this.fields)
            formData.append('theme', JSON.stringify({
                primary: this.fields.primary,
                secondary: this.fields.secondary
            }))
            

            await Branding.configure(formData).then(() => {

                this.success = true;

                this.loading = false;
                
                }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                this.$refs.form.setErrors(err.response.data.errors);
                
                return;
                }

                this.error.title = 'ERROR: ' + " " + err.response.status;

                this.error.message = err.response.data.message;

            });
        }
    },
    computed: {
        crumbs: function() {
            return [
                {
                text: this.$t('menu.dashboard'),
                disabled: false,
                to: '/admin',
                exact: true,
                },
                {
                text: this.$t('menu.white_labeling'),
                disabled: true,
                to: window.location.href,
                exact: true,
                },
            ];
        }
    }
}
</script>