import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Login2Fa from '../views/Login2Fa.vue'
import EmailConfirmation from '../views/EmailConfirmation.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Dashboard from '../views/Dashboard.vue'
import NotFound from '../views/NotFound.vue'
import Roles from '../views/settings/roles/Roles.vue'
import CreateRole from '../views/settings/roles/CreateRole.vue'
import UpdateRole from '../views/settings/roles/UpdateRole.vue'
import Users from '../views/settings/users/Users.vue'
import CreateUser from '../views/settings/users/CreateUser.vue'
import UpdateUser from '../views/settings/users/UpdateUser.vue'
import Layout from '../Layout.vue'
import Logs from '../views/logs/Manage.vue'

import Departments from '../views/settings/departments/Departments.vue'
import UpdateDepartment from '../views/settings/departments/UpdateDepartment.vue'
import CreateDepartment from '../views/settings/departments/CreateDepartment.vue'
import Categories from '../views/complaints/categories/Categories.vue'
import UpdateCategory from '../views/complaints/categories/UpdateCategory.vue'
import CreateCategory from '../views/complaints/categories/CreateCategory.vue'
import States from '../views/complaints/states/States.vue'
import UpdateState from '../views/complaints/states/UpdateState.vue'
import CreateState from '../views/complaints/states/CreateState.vue'

import Complaints from '../views/complaints/Complaints.vue'
import UpdateComplaint from '../views/complaints/UpdateComplaint.vue'

import Export from '../views/exports/Exports.vue'

import Notifications from '../views/Notifications.vue'

import Subscriptions from '@/views/settings/subscriptions/Subscriptions.vue'

import InvalidSubscription from '@/views/InvalidSubscription.vue'

import Whitelabeling from '@/views/settings/whitelabeling/Whitelabeling.vue'

import PaymentConfirmed from "@/views/payment_confirmed/PaymentConfirmed.vue"

import Account from "@/views/settings/account/Account.vue"

import Tutorial from "@/views/settings/tutorial/Tutorial.vue"


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'normal-login',
        component: Login
    },
    {
        path: '/promocode/client-promotion/:code',
        name: 'email-promotion',
        component: Login
    },
    {
        path: '/confirmemail',
        name: 'confirm-email',
        component: Login
    },
    {
        path: '/register/start-free',
        name: 'register-start-free',
        component: Login
    },
    {
        path: '/promocode/:code/:plan/register',
        name: 'promocional-plan-register',
        component: Login
    },
    {
        path: '/promocode/:code/register',
        name: 'promocional-register',
        component: Login
    },
    {
        path: '/promocode/:code',
        name: 'promocional',
        component: Login
    },
    {
        path: '/promocode/:code/:plan',
        name: 'promocional-plan',
        component: Login
    },
    {
        path: '/2fa',
        component: Login2Fa
    },
    {
        path: '/:id/:hash/confirmemail',
        component: EmailConfirmation
    },
    {
        path: '/reset/:id/:hash',
        component: ResetPassword
    },
    {
        path: '/register',
        component: InvalidSubscription
    },
    {
        path: '/:tenant/login',
        name: 'register-tenant',
        component: Login
    },
    {
        path: '/admin',
        component: Layout,
        children: [
            {
                path: '/:tenant/admin/tutorial',
                component: Tutorial
            },
            {
                path: '/:tenant/admin',
                component: Dashboard
            },
            {
                path: '/:tenant/thankyou',
                component: PaymentConfirmed
            },
            {
                path: '/:tenant/invalid-subscription',
                component: InvalidSubscription
            },
            {
                path: '/:tenant/admin/notifications',
                component: Notifications
            },
            {
                path: '/:tenant/admin/exports',
                component: Export
            },
            {
                path: '/:tenant/admin/settings/account',
                component: Account
            },
            {
                path: '/:tenant/admin/settings/roles',
                component: Roles,
            },
            {
                path: '/:tenant/admin/settings/roles/create',
                component: CreateRole,
            },
            {
                path: '/:tenant/admin/settings/roles/:id',
                component: UpdateRole,
            },
            {
                path: '/:tenant/admin/settings/users',
                component: Users
            },
            {
                path: '/:tenant/admin/settings/my-user/:id',
                component: UpdateUser
            },
            {
                path: '/:tenant/admin/settings/logs',
                component: Logs
            },
            {
                path: '/:tenant/admin/settings/users/create',
                component: CreateUser,
            },
            {
                path: '/:tenant/admin/settings/users/:id',
                component: UpdateUser,
            },
            {
                path: '/:tenant/admin/settings/departments',
                component: Departments,
            },
            {
                path: '/:tenant/admin/settings/departments/create',
                component: CreateDepartment,
            },
            {
                path: '/:tenant/admin/settings/subscriptions',
                component: Subscriptions
            },
            {
                path: '/:tenant/admin/settings/departments/:id',
                component: UpdateDepartment,
            },
            {
                path: '/:tenant/admin/settings/categories',
                component: Categories,
            },
            {
                path: '/:tenant/admin/settings/categories/create',
                component: CreateCategory,
            },
            {
                path: '/:tenant/admin/settings/categories/:id',
                component: UpdateCategory,
            },
            {
                path: '/:tenant/admin/settings/states',
                component: States,
            },
            {
                path: '/:tenant/admin/settings/states/create',
                component: CreateState,
            },
            {
                path: '/:tenant/admin/settings/states/:id',
                component: UpdateState,
            },
            {
                path: '/:tenant/admin/whitelabeling',
                component: Whitelabeling
            },
            {
                path: '/:tenant/admin/complaints/list',
                component: Complaints,
            },
            {
                path: '/:tenant/admin/complaints/:id',
                component: UpdateComplaint,
            },
        ]
    },
    { path: '*', component: NotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
