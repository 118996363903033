<template>
    <section class="mt-16">
        <v-breadcrumbs
            v-if="this.$root.$vuetify.theme.isDark"
            large
            :items="crumbs"
            divider="/"
            light
            :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
        ></v-breadcrumbs>
        <v-breadcrumbs
            v-else
            :items="crumbs"
            divider="/"
            light
            :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
        ></v-breadcrumbs>
        
        <v-progress-linear v-if="loading"
        indeterminate
        class="global-loader"
        ></v-progress-linear>

        <h1 class="primary--text">
        <v-icon color="primary">mdi-bell</v-icon> <span>{{$t('notifications.crumb_title')}}</span>
        </h1>

        <v-timeline
            v-if="items.length > 0"
            class="mt-15"
            align-top
            :dense="$vuetify.breakpoint.smAndDown"
            >
            <v-timeline-item
                v-for="(item, i) in items"
                :key="i"
                :color="item.read_at != null ? 'grey' : 'primary'"
                :icon="item.icon"
                fill-dot
                large
        >
        <v-card
            v-if="item.data.type == 'delay' || item.data.type == 'new'"
            :color="'primary'"
            dark
        >
            <v-card-title class="text-h6">
                {{item.data.title}}
            </v-card-title>
            <v-card-text class="white text--primary">
               
                <v-row>
                    <v-col cols="12" v-html="item.data.description">
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-btn
                            :color="'primary'"
                            class="mx-0"
                            @click="checkNotification(item)"
                            x-small
                            >
                                <v-icon x-small class="mr-2">mdi-eye</v-icon>{{$t('globals.see')}}
                            </v-btn>
                    </v-col>
                    <v-col cols="12" md="10" justify="end" align="end">
                            <v-row justify="end" align="end">
                                <v-col cols="12" md="auto">
                                    <span class="text-caption">{{$t('globals.mark_as')}}:</span>
                                </v-col>
                                <v-col cols="12" md="auto" v-if="item.read_at == null">
                                    <v-btn
                                        :color="'warning'"
                                        class="mx-0"
                                        @click="readNotification(item)"                                        
                                        x-small
                                    >
                                        <v-icon x-small class="mr-2">mdi-read</v-icon>{{$t('globals.read')}}
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" md="auto" v-else>
                                    <v-btn
                                        :color="'error'"
                                        class="mx-0"
                                        @click="unReadNotification(item)"
                                        x-small
                                    >
                                        <v-icon x-small class="mr-2">mdi-email-mark-as-unread</v-icon>{{$t('globals.unread')}}
                                    </v-btn>
                                </v-col>
                            </v-row>
                    </v-col>
                    
                </v-row>
            </v-card-text>
        </v-card>
        
            </v-timeline-item>
        </v-timeline>

        <div v-else>
            <v-row class="mt-8 text-h3" justify="center">
                <v-col cols="12" md="auto">
                    {{$t('globals.no_nots')}}
                </v-col>
            </v-row>
            
            <v-row justify="center">
                <v-col cols="12" md="auto">
                    <v-icon x-large>mdi-forum</v-icon>
                </v-col>
            </v-row>
        </div>

    </section>
</template>
<script>
import Notifications from '@/api/Notification.js'
export default{
    name: "Notifications",
    data(){
        return{
            items: [],
            loading: true
        }
    },
    mounted(){
        this.fillBaseData()
    },
    beforeCreate(){
      document.title = this.$t('app_name') + ' - ' + this.$t('notifications.crumb_title')
    },
    methods:{
        unReadNotification(item){
            Notifications.unRead(item.id).then(()=>   {
                window.location.reload();
            });
        },
        readNotification(item){
            Notifications.read(item.id).then(()=>   {
                window.location.reload();
            });
        },
        checkNotification(item){
            Notifications.read(item.id).then(() => {

                this.items.forEach(notification => {
                    if(notification.id == item.id)
                        notification.read_at = '';                   

                });
            });
            this.$router.push('/' + this.$session.get('domain') + '/admin/complaints/' + item.data.url)
        },
        async fillBaseData(){
            await Notifications.list().then(({data}) => {
                this.items = data
                this.loading = false
            });
        }
    },
    computed: {
        crumbs: function() {
            return [
                {
                text: this.$t('menu.dashboard'),
                disabled: false,
                to: '/admin',
                exact: true,
                },
                {
                text: this.$t('notifications.crumb_title'),
                disabled: true,
                to: window.location.href,
                exact: true,
                },
            ];
        }
    }
}
</script>